import { createAsyncThunk } from '@reduxjs/toolkit';
import debug from '../utils/debug';
import { OrderingOperations } from 'polygon-ordering';
import EVENTS from '../constants/events';
import { logEvent } from '../utils/analytics';
import history from '../history';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import { setLocationWhitelist } from '../slices/locationWhitelist';
import { adjustSaleType } from './adjustSaleType';
import { adjustLocation } from './adjustLocation';
import { adjustOrder } from './adjustOrder';
import { adjustRegistrationCode } from './adjustRegistrationCode';
import { updateLoadingDependencies } from '../slices/loadingDependencies';

const { setTable, startNewOrder, setCurrentMenuBrandId } = OrderingOperations;

export const handleUrlParams = createAsyncThunk(
  'handleUrlParams',
  async (_data: undefined, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(updateLoadingDependencies({ handleURLParamsInProgress: true }));
      const { searchParams, pathname } = new URL(window.location.href);

      const preserveOrderParam = getSearchParam(searchParams, ['preserveOrder']);
      const preserveOrder =
        preserveOrderParam === '1' || String(preserveOrderParam).toLowerCase() === 'true';

      const locationId = getSearchParam(searchParams, LOCATION_ID_ALIASES, true);
      const saleType = getSearchParam(searchParams, SALE_TYPE_ALIASES, true);
      const tableNumber = getSearchParam(searchParams, TABLE_NUMBER_ALIASES);
      let brandOverride = getSearchParam(searchParams, BRAND_OVERRIDE_ALIASES);
      const registrationCode = getSearchParam(searchParams, REGISTRATION_CODE_ALIASES);

      const locationWhitelist = (
        (getSearchParam(searchParams, LOCATION_WHITELIST_ALIASES) || '') as string
      )
        .split(',')
        .filter(id => id !== '');

      const params = {
        locationId,
        saleType,
        tableNumber,
        locationWhitelist,
        preserveOrder,
        registrationCode,
        raw: window.location.search,
      };
      debug('URL PARAMS', { params, pathname });

      // TODO: refactor/fix this reset password stuff
      if (params.raw && !(pathname.endsWith('/reset') || pathname.endsWith('/verify'))) {
        logEvent(EVENTS.LOAD_WITH_URL_PARAMS, { label: params.raw });

        // remove params from URL bar (does not reload)
        history.replace('');
      }

      const anyPresent = locationId != null || saleType != null || locationWhitelist.length > 0;

      if (anyPresent && !preserveOrder) {
        await dispatch(startNewOrder({ preserveMember: true }));
      }

      if (
        tableNumber != null &&
        (saleType === SALE_TYPE.DINE_IN || saleType === SALE_TYPE.TABLE_ORDER)
      ) {
        dispatch(setTable(tableNumber as string));
      }

      const ignoreLocationId = saleType === SALE_TYPE.DELIVERY;

      if (locationWhitelist.length) {
        dispatch(setLocationWhitelist(locationWhitelist));
      } else if (anyPresent) {
        dispatch(setLocationWhitelist([]));
      }

      if (registrationCode != null) {
        debug('initiating registration code change');

        dispatch(
          adjustRegistrationCode({
            registrationCode: registrationCode as string,
          }),
        );
      } else if (saleType != null && (locationId == null || ignoreLocationId)) {
        debug('initiating sale type change');

        dispatch(
          adjustSaleType({ saleType: saleType as number, locationId: locationId as string }),
        );
      } else if (locationId != null && saleType == null) {
        debug('initiating location change');

        dispatch(
          adjustLocation({
            locationId: locationId as number,
            initialScreen: false,
          }),
        );
      } else if (locationId != null && saleType != null) {
        debug('initiating sale type and location change');
        dispatch(updateLoadingDependencies({ redirectRequiresLocationData: true }));
        dispatch(
          adjustOrder({
            saleType: saleType as number,
            locationId: locationId as string,
          }),
        );
      }

      if (locationId != null && brandOverride != undefined) {
        debug('brand overide and location id exists, redirecting to brand from specified location');
        dispatch(setCurrentMenuBrandId(String(brandOverride)));
      }
      dispatch(updateLoadingDependencies({ handleURLParamsInProgress: false }));
      return { dontAttemptMenuUpdate: anyPresent };
    } catch (e) {
      const message = 'Issue processing URL params. Please check your URL.';
      console.error('Handle url params failed', message, e);
      dispatch(updateLoadingDependencies({ handleURLParamsInProgress: false }));
      return rejectWithValue(e);
    }
  },
);

const getSearchParam = (
  searchParams: URLSearchParams,
  keys: string[] = [],
  isInt?: boolean,
): string | number | undefined => {
  let keysArray = keys;

  if (!Array.isArray(keysArray)) {
    keysArray = [keysArray];
  }

  let result;

  keysArray.some(key => {
    result = searchParams.get(key);
    return result != null;
  });

  return isInt && result ? parseInt(result) : result;
};

const LOCATION_ID_ALIASES = [
  'locationId',
  'locationid',
  'location',
  'location_id',
  'store',
  'storeid',
  'storeId',
  'store_id',
];

const SALE_TYPE_ALIASES = [
  'saleType',
  'saletype',
  'sale_type',
  'order_type',
  'ordertype',
  'orderType',
];

const TABLE_NUMBER_ALIASES = ['tableNumber', 'tablenumber', 'table_num'];

const LOCATION_WHITELIST_ALIASES = ['locations', 'stores', 'locationIds', 'storeIds'];

const BRAND_OVERRIDE_ALIASES = ['brand', 'brand_id'];

const REGISTRATION_CODE_ALIASES = ['registrationCode', 'registrationcode', 'registration_code'];

const REFERRING_MEMBER_ALIASES = ['referringMember', 'referringmember', 'referring_member'];
