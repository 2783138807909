import React, { useEffect, useState } from 'react';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import ModalFloatingHeader from './ModalFloatingHeader';
import Text from './Text';
import { useAppSelector } from '../app/hooks';
import { FILTERS_MODAL_ID } from '../modals/FiltersModal';

import { CSSTransition } from 'react-transition-group';

interface IProps {
  title?: string;
  closeButtonLabel?: string;
  previousModalId: string | null;
  locked?: boolean;
  clickBackgroundToClose: boolean;
  setCurrentModal: (params: ModalParams) => void;
  containerStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  mobileContainerStyle?: React.CSSProperties;
  backdropStyle?: React.CSSProperties;
  tallestPossible?: boolean;
  hideFloatingHeader?: boolean;
  handleBack?: () => void;
  handleClose?: () => void;
  enableBackButton?: boolean;
  enableCloseButton?: boolean;
  headerContainerStyle?: React.CSSProperties;
}

const ModalMobile: React.FC<IProps> = ({
  title,
  clickBackgroundToClose,
  children,
  previousModalId,
  setCurrentModal,
  containerStyle,
  wrapperStyle,
  backdropStyle,
  titleStyle,
  mobileContainerStyle,
  tallestPossible,
  locked,
  hideFloatingHeader,
  handleBack,
  handleClose,
  enableBackButton,
  enableCloseButton,
  headerContainerStyle,
}) => {
  const close = () => {
    setMounted(false);

    setTimeout(() => {
      setCurrentModal(null);
    }, 300);
  };
  const goBack =
    handleBack ?? (() => previousModalId && setCurrentModal({ modalId: previousModalId }));
  const p = useAppSelector(getThemeLookup);
  const activeModal = useAppSelector(state => state.currentModalId);

  // odd way of doing this, not sure what the best way would be but there has to be one
  const nonRadiusModals = [FILTERS_MODAL_ID];

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
      console.log('unmounting modal callout');
    };
  }, []);

  return (
    <div>
      <CSSTransition in={mounted} timeout={300} classNames="rc-slide-up" unmountOnExit>
        <div
          style={combineStyles(
            styles.overlay,
            nonRadiusModals.includes(activeModal!) && styles.memberOverlay,
            backdropStyle,
          )}
          onClick={!locked && clickBackgroundToClose ? () => close() : undefined}
          className="modalOverlay"
        >
          <div
            style={combineStyles(
              styles.wrapper,
              tallestPossible && styles.tallestPossibleWrapper,
              wrapperStyle,
            )}
            onClick={event => event.stopPropagation()} // don't trigger the overlay's onClick event
            className="modalWrapper"
          >
            <div
              style={combineStyles(
                nonRadiusModals.includes(activeModal!) ? styles.memberModal : styles.modal,
                tallestPossible && styles.tallestPossibleModal,
                p('screen', ['backgroundColor']),
                p('modalMobile', ['backgroundColor']),
                containerStyle,
                mobileContainerStyle,
              )}
              className="modalBody"
            >
              {/* TODO: remove the modal title prop */}
              {title && (
                <Text
                  themeKey="modalTitle"
                  style={combineStyles(styles.title, titleStyle ? titleStyle : '')}
                >
                  {title}
                </Text>
              )}
              {children}
            </div>
            {!hideFloatingHeader && (
              <ModalFloatingHeader
                onGoBack={goBack}
                onClose={handleClose ?? close}
                locked={locked}
                backButtonEnabled={enableBackButton}
                closeButtonEnabled={enableCloseButton}
                containerStyle={headerContainerStyle}
              />
            )}
          </div>

          {/* for transitions */}
          <div className="modal-overlay">
            <div
              style={combineStyles(
                p('modalOverlay', ['backgroundColor']),
                p('modalOverlayMobile', ['backgroundColor']),
              )}
            ></div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

const styles: Styles = {
  overlay: {
    zIndex: 200, // If higher than 1300, select field options will not be visible when opened. ModalDesktop sits at 200, so this will too.
    position: 'absolute',
    top: 0,
    left: 0,

    width: '100%',
    height: '100%',
    maxHeight: '100%',
    overflow: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  memberOverlay: {
    marginTop: 55, // height of header
  },

  wrapper: {
    width: '100%',
    maxHeight: '100%',
    overflow: 'none',
    display: 'flex',
    flexDirection: 'column',
  },

  memberModal: {
    padding: '40px 40px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  modal: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: '40px 40px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  title: {
    textAlign: 'center',
  },

  tallestPossibleWrapper: {
    height: '100%',
    maxHeight: '100%',
    // paddingBottom: 20,
  },
  tallestPossibleModal: {
    flex: 1,
  },
};

export default ModalMobile;
