import processOffer from '../libs/polygon-ordering/src/utils/processors/processOffer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as null | Offer[];

const filterOffers = (offers: Offer[]) => {
  const currentDateTime = new Date().getTime();
  return offers.filter(offer => {
    // Filtering based on expiry date
    if (offer.expiryDate && new Date(offer.expiryDate).getTime() < currentDateTime) return false;

    return true;
  });
};

const slice = createSlice({
  name: 'memberOffers',
  initialState,
  reducers: {
    storeMemberOffers(state, action: PayloadAction<null | RawOffer[]>) {
      if (action.payload === null) {
        return initialState;
      }
      const processed_offers = action.payload.map(rawOffer => processOffer(rawOffer)) as Offer[];

      return filterOffers(processed_offers);
    },
  },
});

export const { storeMemberOffers } = slice.actions;

export default slice.reducer;
