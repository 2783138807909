import { createSelector } from 'reselect';
import { RootState } from 'store';

import { formatDate } from '../utils/misc';

const _getMemberOffers = (state: RootState) => state.memberOffers;

// NOTE: if just after midnight, only use the date component
const formatOfferDate = (date: string) => {
  const formatted = formatDate(date, 'DD/MM/YYYY h:mmA');

  return formatted ? formatted.replace(/\s+12:0.AM$/, '') : undefined;
};

export default createSelector([_getMemberOffers], memberOffers => {
  if (memberOffers === null) {
    return undefined;
  }

  return memberOffers.map(memberOffer => ({
    ...memberOffer,
    formattedStartDate: formatOfferDate(memberOffer.startDate!),
    formattedExpiryDate: formatOfferDate(memberOffer.expiryDate!),
  }));
});
