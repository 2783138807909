import { RedcatApiHandler } from 'polygon-utils';

import { storeMemberOffers } from '../slices/memberOffers';

const fetchMemberOffers =
  ({ memberId }: { memberId?: number }) =>
  (dispatch: any) => {
    const path = `/api/v1/profile/coupons?hideredeemed=1&hideexpired=1${
      memberId !== undefined ? `&memberid=${memberId}` : ''
    }`;
    return RedcatApiHandler.authorisedFetch({ method: 'GET', path }).then(response => {
      dispatch(storeMemberOffers(response.data));
    });
  };

export default fetchMemberOffers;
