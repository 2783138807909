import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { useTranslation } from 'react-i18next';
import RedcatImage from '../components/RedcatImage';
import Text from '../components/Text';
import getProfile from '../selectors/getProfile';
import TouchableOpacity from '../components/TouchableOpacity';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { setLoyaltySelectedOffer } from '../slices/loyaltySelectedOffer';
import { LOYALTY_OFFER_MODAL_ID } from '../modals/LoyaltyOfferModal';
import SendVerificationButton from '../components/SendVerificationButton';
import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES } from '../utils/theme';
import getThemeLookup from '../selectors/getThemeLookup';
import getMemberOffers from '../selectors/getMemberOffers';
import _fetchMemberOffers from '../thunks/fetchMemberOffers';
import { updateLoadingDependencies } from '../slices/loadingDependencies';

export const OFFERS_ROUTE = '/offers';

const OffersScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const offers = useAppSelector(getMemberOffers);
  const profile = useAppSelector(getProfile);
  const { verified } = profile!;

  useEffect(() => {
    if (verified) {
      if (offers === undefined) dispatch(updateLoadingDependencies({ fetchingMemberOffers: true }));
      setTimeout(() => {
        dispatch(_fetchMemberOffers({})).finally(() =>
          dispatch(updateLoadingDependencies({ fetchingMemberOffers: false })),
        );
      });
    }
  }, [verified]);

  const p = useAppSelector(getThemeLookup);
  const { t } = useTranslation();

  return (
    <div className="text-center" style={{ marginTop: 10 }}>
      {!profile?.verified ? (
        <div style={{ padding: '3em 0px' }}>
          <Text
            value={t('offersIfNotVerified')}
            style={combineStyles({ fontSize: 14 }, p('offersText', TEXT_PROPERTIES))}
          />
          <SendVerificationButton containerStyle={{ paddingTop: 20 }} />
        </div>
      ) : (
        <>
          {offers?.map((offer, i) => (
            <TouchableOpacity
              key={i}
              onClick={() => {
                dispatch(setLoyaltySelectedOffer(offer));
                dispatch(setCurrentModal(LOYALTY_OFFER_MODAL_ID));
              }}
            >
              <div
                style={{
                  backgroundColor: 'whitesmoke',
                  borderRadius: 10,
                  marginBottom: '20px',
                }}
                className="shadow-lg "
              >
                {!!offer.image && (
                  <RedcatImage
                    imagePath={offer.image}
                    imgFluid
                    imageStyle={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
                  />
                )}
                <div>
                  <div>
                    <Text value={offer.name} style={{ fontWeight: 'bold', fontSize: 12 }} />
                    <div>
                      <Text
                        value={t('moreInformation')}
                        style={{ fontWeight: 'bold', fontSize: 12 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TouchableOpacity>
          ))}
        </>
      )}
    </div>
  );
};

export default OffersScreen;
