import React from 'react';
import { useAppSelector } from '../app/hooks';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@material-ui/core';
import getThemeLookup from '../selectors/getThemeLookup';
import { useFormikContext } from 'formik';
import MenuItem from '@material-ui/core/MenuItem';
import { TEXT_PROPERTIES } from '../utils/theme';
import combineStyles from '../utils/combineStyles';

const FormSelect = ({
  name,
  label,
  required = false,
  onChange,
  disabled,
  menuItems = [],
  disableStyles = false,
  shrinkLabel = true,
}: {
  name: string;
  required?: boolean;
  label: string;
  onChange: (e: any) => void;
  disableStyles?: boolean;
  disabled?: boolean;
  menuItems: any;
  shrinkLabel?: boolean;
}) => {
  const p = useAppSelector(getThemeLookup);

  const sharedInputProps = {
    InputProps: {
      style: {
        //textfield text filled colors
        color: p('textColor', ['color']).color,
        ...p('defaultText', TEXT_PROPERTIES),
      },
    },
    InputLabelProps: {
      // style: {
      //   //textfield label - placeholder colors
      //   color: p('labelColor', ['color']).color,
      //   font: p('labelColor', ['font']).font,
      // },
      style: combineStyles(p('defaultText', TEXT_PROPERTIES), p('formLabel', TEXT_PROPERTIES)),
    },
  };

  const useStyles = makeStyles(() => ({
    caretIcon: {
      color: `${p('caretColor', ['color']).color} !important`,
    },
    dropdown: {
      '& .MuiSelect-select': {
        //selected menu item color
        color: p('formTextFieldInput', ['color']).color,
      },
    },
    errors: {
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${p('materialTextFieldErrorBorder', ['color']).color} !important`,
        },
      },
    },
    root: {
      '& ::-webkit-calendar-picker-indicator': {
        filter: `invert(${p('fieldIcons', ['color']).color})`,
      },
      //label - placeholder background color
      '& .MuiFormLabel-root': {
        backgroundColor: p('labelBackgroundColor', ['color']).color,
        borderRadius: 5,
        paddingLeft: 5,
        paddingRight: 5,
      },

      //text field errors
      '&  .MuiFormHelperText-root.Mui-error': {
        backgroundColor: p('helperBackground', ['color']).color,
        color: p('helperColor', ['color']).color,
      },
      // focused color for textfield with variant='outlined'
      '& .MuiOutlinedInput-root': {
        //textfield background color
        background: p('fieldBackground', ['color']).color,

        //text field border unfocused
        '& fieldset': {
          borderColor: p('fieldDefaultBorder', ['color']).color,
        },
        //text field border focused
        '&.Mui-focused fieldset': {
          borderColor: p('fieldBorderFocus', ['color']).color,
        },
        //text field border hover
        '&:hover fieldset': {
          borderColor: p('fieldHoverBorderColor', ['color']).color,
        },
      },
      // default label color
      '& .MuiInputLabel-root': {
        color: 'gray !important',
      },
      // label color when focused
      '& .MuiInputLabel-root.Mui-focused': {
        color: 'gray !important',
      },
    },
  }));

  const muiStyles = useStyles();
  const formik = useFormikContext<FormikFields>();

  const { errors, setFieldTouched, touched, values } = formik;

  return (
    <TextField
      fullWidth
      style={disableStyles ? {} : styles.inputField}
      required={required}
      //caret color
      SelectProps={{
        classes: {
          icon: muiStyles.caretIcon,
        },
      }}
      size="small"
      label={label}
      name={name}
      value={
        values[name as keyof FormikFields] === undefined ||
        values[name as keyof FormikFields] === null
          ? ''
          : values[name as keyof FormikFields]
      }
      onChange={onChange}
      onBlur={() => setFieldTouched(name)}
      error={Boolean(touched[name as keyof FormikFields] && errors[name as keyof FormikFields])}
      disabled={disabled ?? false}
      variant="outlined"
      select
      helperText={touched[name as keyof FormikFields] ? errors[name as keyof FormikFields] : ''}
      className={
        errors[name as keyof FormikFields]
          ? muiStyles.root + ' ' + muiStyles.errors
          : muiStyles.root + ' ' + muiStyles.dropdown
      }
      InputLabelProps={{
        shrink: shrinkLabel,
        ...sharedInputProps.InputLabelProps,
      }}
      InputProps={{ ...sharedInputProps.InputProps }}
    >
      {menuItems?.map((e: any, index: any) => (
        <MenuItem key={index} value={e.value || e.id || e}>
          {e.label || e.name || e}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default FormSelect;

const styles: Styles = {
  inputField: {
    marginBottom: 10,
    marginTop: 10,
  },
};
